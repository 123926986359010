<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
                destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="报溢单ID" prop="baoYiDanID" >
                {{form.baoYiDanID||"保存后自动生成"}}
            </el-form-item>
            <el-form-item label="报溢时间" prop="baoYiSJ">
                <el-date-picker v-model="form.baoYiSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
<!--            <el-form-item label="报溢商品种数" prop="baoYiShangPinZhongShu" >-->
<!--                <el-input v-model="form.baoYiShangPinZhongShu" size="small" maxlength="" show-word-limit/>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="报溢总金额" prop="baoYiZongJinE" >-->
<!--                <el-input v-model="form.baoYiZongJinE" size="small" maxlength="18" show-word-limit/>-->
<!--            </el-form-item>-->
            <el-form-item label="报溢人" prop="baoYiRen" >
                <x-user-selector v-model="form.baoYiRen" :name.sync="form.name" style="width: 100%"/>
            </el-form-item>
<!--            <el-form-item label="报溢人ID" prop="baoYiRenID" >-->
<!--                <el-input v-model="form.baoYiRenID" size="small" maxlength="36" show-word-limit/>-->
<!--            </el-form-item>-->
            <el-form-item label="备注" prop="beiZhu" >
                <el-input v-model="form.beiZhu" size="small" maxlength="255" show-word-limit/>
            </el-form-item>
            <el-form-item label="主要事项" prop="title" >
                <el-input v-model="form.title" size="small" maxlength="100" show-word-limit/>
            </el-form-item>
            <x-divider title="审核信息"></x-divider>
            <el-form-item label="审核状态">
                <x-dict-show :code="form.auditStatus" dictType="T_AUDIT_STATUS"/>
            </el-form-item>
            <el-form-item label="审核日期">{{form.auditDate}}</el-form-item>
            <el-form-item label="审核人">{{form.auditorName}}</el-form-item>
            <el-form-item label="审核意见" class="line">{{form.auditMind}}</el-form-item>
            <x-divider title="报溢信息详情"></x-divider>
            <x-selector
                    placement="center"
                    selector="KuCunZhuangKuangSelector"
                    @selected="handleClSelect"
                    code-key="pandiankey"
                    selection
                    width="1000px">
                <span style="color:blue;cursor: pointer;">选择商品</span>
            </x-selector>
            <el-table :data="form.baoYiXinXiXiangQings" border class="x-detail-table">
                <el-table-column width="50" label="序号" type="index"/>
                <el-table-column width="250" prop="baoYiCangKuID" label="报溢仓库ID"/>
                <el-table-column width="200" prop="baoYiCangKuMC" label="报溢仓库名称"/>
                <el-table-column width="200" prop="baoYiShangPinID" label="报溢商品ID"/>
                <el-table-column width="200" prop="baoYiShangPinMC" label="报溢商品名称"/>
                <el-table-column width="150" prop="guiGe" label="规格"/>
                <el-table-column width="150" prop="tiaoMa" label="条码"/>
                <el-table-column width="150" prop="huoHao" label="货号"/>
                <el-table-column width="100" prop="danWei" label="单位"/>
                <el-table-column width="100" prop="chengBenJia" label="成本价"/>
                <el-table-column width="200" prop="shengChanRQ" label="生产日期"/>
                <el-table-column width="100" prop="baoZhiQiTian" label="保质期（天）"/>
                <el-table-column width="100" prop="dangQianKuCunSL" label="当前库存数量"/>
                <el-table-column width="100" label="报溢数量">
                    <el-input slot-scope="{row}" v-model="row.baoYiSL" size="small" maxlength="18"/>
                </el-table-column>
                <el-table-column width="200" label="备注">
                    <el-input slot-scope="{row}" v-model="row.beiZhu" size="small" maxlength="18"/>
                </el-table-column>
            </el-table>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button type="primary" @click="commit" icon="el-icon-document-add">提 交</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/kcgl/BaoYiXX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import XUserSelector from "@/components/x/selector/XUserSelector";
    import KuCunZhuangKuangSelector from "@/view/selector/KuCunZhuangKuangSelector";

    export default {
        mixins: [XEditBase],
        components: {XUserSelector,KuCunZhuangKuangSelector},
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                baoYiDanID: [], // 报溢单ID
                baoYiSJ: [required()], // 报溢时间
                baoYiShangPinZhongShu: [], // 报溢商品种数
                baoYiZongJinE: [], // 报溢总金额
                baoYiRen: [required()], // 报溢人
                baoYiRenID: [], // 报溢人ID
                beiZhu: [], // 备注
                title: [], // 主要事项
            }
            this.titlePrefix = '报溢信息';
            this.baoYiXinXiXiangQingsModel = {
                baoYiCangKuID: "", // 报溢仓库ID
                baoYiCangKuMC: "", // 报溢仓库名称
                baoYiShangPinID: "", // 报溢商品ID
                baoYiShangPinMC: "", // 报溢商品名称
                guiGe: "", // 规格
                tiaoMa: "", // 条码
                huoHao: "", // 货号
                danWei: "", // 单位
                chengBenJia: "", // 成本价
                shengChanRQ: "", //生产日期
                baoZhiQiTian: "", //保质期（天）
                dangQianKuCunSL: "", // 当前库存数量
                baoYiSL: "", // 报溢数量
                baoYiJinE: "", // 报溢金额
                beiZhu: "", // 备注
            }
            this.defaultForm = {
                id: null,
                baoYiDanID: "", // 报溢单ID
                baoYiSJ: "", // 报溢时间
                baoYiShangPinZhongShu: "", // 报溢商品种数
                baoYiZongJinE: "", // 报溢总金额
                baoYiRen: this.$store.getters.user.name, // 报溢人
                baoYiRenID: this.$store.getters.user.id, // 报溢人ID
                beiZhu: "", // 备注
                title: "", // 主要事项
                auditStatus: "",
                auditDate: "",
                auditorName: "",
                auditMind: "",
                baoYiXinXiXiangQings: [],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        methods: {
            handleClSelect(cls) {
                const baoYiXinXiXiangQings = this.form.baoYiXinXiXiangQings;
                cls.forEach(cl => {
                    let obj = {};
                    obj.baoYiCangKuID = cl.cangKuBH;
                    obj.baoYiCangKuMC = cl.cangKuMC;
                    obj.baoYiShangPinID = cl.shangPinHuoHao;
                    obj.baoYiShangPinMC = cl.shangPinMC;
                    obj.danWei = cl.shangPinDW;
                    obj.chengBenJia = cl.chengBenJia;
                    obj.shengChanRQ = cl.shengChanRQ;
                    obj.baoZhiQiTian = cl.baoZhiQiTian;
                    obj.dangQianKuCunSL = cl.shiJiKuCun;
                    baoYiXinXiXiangQings.push(obj);
                });
            },

        }
    }
</script>

<style scoped>

</style>